@font-face {
  font-family: "04B11-xrr";
  src: local("04B11-xrr"), url("./fonts/04B11-xrr.ttf") format("truetype");
  font-weight: normal;
}

* {
  font-family: "04B11-xrr";
  font-weight: normal;
  color: white;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

.body {
  font-family: "04B11-xrr";
  min-width: 450px;
}
p {
  font-size: 0.8em;
}

body,
html {
  /* The image used */
  background-color: rgb(0, 0, 0);
  /* Full height */
  height: 100%;
  max-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}
path {
  fill: transparent;
}

text {
  fill: #ff9800;
  font-size: 25px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Action-button-group {
  position: "absolute";
  bottom: 70px;
  right: 50%;
}

.App-body {
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.Card {
  margin: 15px;
}

@media screen and (min-width: 1366px) {
}

#UserNFTPanel::-webkit-scrollbar {
  display: none;
}
#UserNFTPanel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#UserSelectedNFTPanel::-webkit-scrollbar {
  display: none;
}
#UserSelectedNFTPanel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.OathDataPanel {
  margin: auto auto auto auto;
  padding: 5px;
  text-align: center;
  display: "table";
  position: "absolute";
  bottom: 30;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textAlignRight {
  text-align: right;
}

.approveBtn {
  position: "absolute";
  bottom: 30;
  left: 50%;
  padding: 5px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.navbarAnim {
  animation: flicker 1.5s linear infinite alternate;
}
@keyframes flicker {
  from {
    box-shadow: 0 0.2em 2px -2px rgb(10, 14, 75);
  }
  45% {
    box-shadow: 0 0.4em 2px -2px rgb(10, 14, 75);
  }
  70% {
    box-shadow: 0 0.2em 2px -2px rgb(10, 14, 75);
  }
  to {
    box-shadow: 0 0.3em 2px -2px rgb(10, 14, 75);
  }
}

.assetImage {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skullyDiv {
  z-index: 1000;
  position: absolute;
  width: 70px;
  height: 70px;
  filter: blur(1px);
  opacity: 0.8;
  transform: translate(0%, 0%);
  animation: pusle 2s linear infinite alternate;
}

@keyframes pusle {
  from {
    transform: scale(0.1) translate(0%, 0%);
    opacity: 0.1;
    filter: blur(3px);
  }

  to {
    transform: scale(1) translate(0%, 0%);
    opacity: 1;
    filter: blur(0px);
  }
}

.warpedLogo {
  animation: warp 5s ease-in infinite alternate;
}

@keyframes warp {
  from {
    transform: scale(1, 1) translate(-50%, 0%) skew(0deg, 0deg);
  }
  to {
    transform: scale(0.95, 1.05) translate(-55%, 20%) skew(-5deg, -5deg);
    filter: blur(1px);
  }
}

.mintedImagePanel {
  position: "absolute";
  bottom: 30px;
  left: 50%;
  padding: 5px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 50%;
}

.animatedButton {
  padding: 5px;
  text-align: center;
  max-width: 50%;
  cursor: pointer;
}

.animatedDiscountButton {
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.nav {
  height: 50px;
  width: 110px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-radius: 0px 0px 5px 0px;
  background-image: -webkit-linear-gradient(30deg, #000000 50%, #c63012e5 50%);

  transition: width 0.5s, height 0.5s;
  transition-delay: 0s, 0.5s;
  overflow: hidden;
}
.nav:focus {
  height: 375px;
  width: 300px;
}
.nav:hover {
  height: 375px;
  width: 300px;
}

@keyframes logoAnim {
  from {
    background-image: -webkit-linear-gradient(15deg, #000000 50%, #af3829 50%);
    transform: skew(-0deg, -0deg);
  }
  50% {
    background-image: -webkit-linear-gradient(30deg, #000000 50%, #af3829 50%);
    transform: skew(-0deg, -0deg);
  }
  60% {
    background-image: -webkit-linear-gradient(30deg, #000000 50%, #af3829 50%);
    transform: skew(2deg, 2deg);
  }
  70% {
    background-image: -webkit-linear-gradient(30deg, #000000 50%, #af3829 50%);
    transform: skew(-0deg, -0deg);
  }
  to {
    background-image: -webkit-linear-gradient(45deg, #000000 50%, #af3829 50%);
    transform: skew(-0deg, -0deg);
  }
}

/* Position the mouse pointer and
the background image */
.main_box,
.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main_box {
  cursor: none;
  margin-top: 3em;
  opacity: 0.8;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Reduce the brightness of the image */
.img {
  filter: brightness(10%);
}

.clownCardFront {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to bottom right, #c63012e5, #7a2e1ee5);
  background-color: #9f3c28e5;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: 2px solid black;
  padding: 1em;
  border-radius: 3% 10% 10% 3%;
}

/* entire container, keeps perspective */
.flip-container {
  height: 100%;
  max-height: 470px;
  perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(540deg);
}

.flip-container,
.front,
.back {
  width: 300px;
  height: 470px;
}

/* flip speed goes here */
.flipper {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}

tr {
  font-size: 12px;
}
tr td:first-child {
  text-align: right;
}

tr td:last-child {
  text-align: left;
  padding-left: 10px;
}

.ringLeaderCards {
  position: absolute;
  transform: translate(-50%, -50%);
  animation: ringLeaderCardsAnim 8s ease-in-out forwards;
}
.ringLeaderCardsIntro {
  position: absolute;
  transform: translate(-50%, -50%);
  animation: ringLeaderCardsAnim 15s ease-in-out forwards;
}
@keyframes ringLeaderCardsAnim {
  from {
    top: 200%;
    left: 45%;
    transform: scale(0.1) translate(-50%, -50%);
  }
  80% {
    top: 200%;
    left: 45%;
    transform: scale(0.1) translate(-50%, -50%) rotateY(0deg);
  }
  to {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(360deg);
  }
}

.ringLeaderIntro {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ringLeaderAnim 15s ease-in-out forwards;
}



.ringLeader {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  animation: ringLeaderAnim 8s ease-in-out forwards;
  z-index: 10;
}

@keyframes ringLeaderAnim {
  from {
    transform: translate(-50%, -50%) scale(0.6);
  }
  80% {
    transform: translate(-50%, -50%) scale(0.6);
  }
  to {
    transform: scale(0.3) translate(35%, -50%);
  }
}
@media screen and (min-width: 1366px) {
  .ringLeader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ringLeaderAnim 8s ease-in-out forwards;
    z-index: 10;
  }

  @keyframes ringLeaderAnim {
    from {
      transform: translate(-50%, -50%);
    }
    80% {
      transform: translate(-50%, -50%);
    }
    to {
      transform: scale(0.3) translate(35%, 100%);
    }
  }
}
.float {
  height: 100%;
  margin: 0 5px;
  transform: translate(0%, 0%);
  animation: float 3s ease-in-out infinite alternate;
}

@keyframes float {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 10px);
  }
}

@keyframes floatShadow {
  from {
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);
  }
  to {
    box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
  }
}

.logo {
  position: absolute;
  left: 50%;
  top: 20px;
  max-height: 200px;
  height: 10%;
  transform: translate(-50%, 0) scale(1.2);
}

.logo-animated {
  position: absolute;
  left: 50%;
  top: 20px;
  max-height: 200px;
  height: 10%;
  transform: translate(-50%, 0) scale(1.2);
  animation: logoAnim 5s ease-in-out forwards;
}

@keyframes logoAnim {
  from {
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  90% {
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  to {
    top: 20px;
    transform: translate(-50%, 0) scale(1.2);
  }
}

*::-webkit-scrollbar {
  display: none;
}
.GalleryContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: "100%";
  overflow-y: "scroll";
}

.mintItemDiv {
  position: relative;
  height: 500px;
}

.typeWriteDiv {
  width: 100%;
  max-width: 600px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -0%);
  max-height: 90%;
  overflow-y: scroll;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 5px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1366px) {
  .typeWriteDiv {
    width: 60%;
  }
}
.connectNetworkText {
  color: "green";
  cursor: "pointer";
}
.connectNetworkText:hover {
  color: "blue";
  cursor: "pointer";
}
.connectNetworkText:active {
  color: "blue";
  cursor: "pointer";
}

.dot1 {
  animation-delay: 0.75s;
}
.dot2 {
  animation-delay: 1.25s;
}
.dot3 {
  animation-delay: 2s;
}

.dot {
  position: absolute;
  left: 50%;
  top: 65px;
  animation: xAxis 2.5s infinite linear;
  z-index: 9;
}

.dot::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fff;
  animation: yAxis 2.5s infinite linear;
}

@keyframes yAxis {
  from {
    opacity: 1;
    animation-timing-function: linear;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    animation-timing-function: linear;
    transform: translateY(-175px);
  }
  50% {
    opacity: 1;
    animation-timing-function: linear;
    transform: translateY(0px);
  }
  75% {
    opacity: 1;
    animation-timing-function: linear;
    transform: translateY(-175px);
  }
}

@keyframes xAxis {
  0% {
    animation-timing-function: linear;
    transform: translateX(-175px);
  }
  50% {
    animation-timing-function: linear;
    transform: translateX(175px);
  }
  100% {
    animation-timing-function: linear;
    transform: translateX(-175px);
  }
}
div:nth-child(1) {
  animation-delay: 0.75s;
}
div:nth-child(1):after {
  animation-delay: 0.75s;
  background-image: "./assets/unassortedclownface01.png";
}

div:nth-child(2) {
  animation-delay: 1.25s;
}
div:nth-child(2):after {
  animation-delay: 1.25s;
}
.selectedNft {
  position: relative;
  margin: 5px;
  height: 60px;
  width: 50%;
  display: flex;
}
