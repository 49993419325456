.clownhead {
  width: 30px;
}

.baseview__animated__div {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 90%;
  transform: translate(-50%, -50%);
}

.background__image,
.background__image__curtains,
.background__image__floor {
  position: fixed;
  left: 50%;
  top: 0;
  height: 100%;
  width: 102%;
  min-width: 1600px;
  transform: translate(-51%, 0);
}

.curtains_open {
  animation: open 2.5s linear forwards;
}
.curtains_close {
  animation: close 2.5s linear forwards;
}

@keyframes open {
  to {
    transform: translate(-51%, -17%);
  }
}
@keyframes close {
  to {
    transform: translate(-51%, 0%);
  }
}

.background__div {
  background-color: rgba(0, 0, 0, 0.8);
  min-height: 100%;
  min-width: 1024px;
  height: auto;
  width: 100%;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
}

.spotlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.spotlight {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Make a circle with the clip-path property
    for the spotlight in the effect */
.spotlight {
  clip-path: circle(100em at 50% 50%);
}

.spotlight__animated {
  clip-path: circle(5em at 500, 500);
  animation: spotLight_keyframe 5s ease-in-out forwards;
}

.spotlight__animated__mint {
  clip-path: circle(5em at 500, 500);
}

@keyframes spotLight_keyframe {
  from {
    clip-path: circle(10em at 0px 0px);
  }
  30% {
    clip-path: circle(10em at 100% 50%);
  }
  50% {
    clip-path: circle(10em at 50% 50%);
  }
  80% {
    clip-path: circle(10em at 50% 50%);
  }
  to {
    clip-path: circle(100em at 50% 50%);
    opacity: 1;
    display: none;
  }
}

@keyframes spotLight_keyframe_mint {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  50%{
    transform: translate(0%, 25%) scale(2);
  }
  to {
    transform: translate(0%, 0%) scale(1);
  }
}

@media screen and (min-width: 1366px) {
  @keyframes spotLight_keyframe {
    from {
      clip-path: circle(20em at 0px 0px);
    }
    30% {
      clip-path: circle(20em at 100% 50%);
    }
    50% {
      clip-path: circle(20em at 50% 50%);
    }
    80% {
      clip-path: circle(20em at 50% 50%);
    }
    to {
      clip-path: circle(100em at 50% 50%);
      opacity: 1;
      display: none;
    }
  }
}

.nftcard {
  position: absolute;
  top: 20%;
  left: 50%;
  display: none;
}

.nftcard__div {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) scale(0.3);
}

.nftcard__div__minting {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) scale(0.3);
  animation: nftcard_keyframe 8s ease-in-out forwards;
  display: flex;
  flex-direction: row;
  height: 500px;
  max-width: 100%;
  padding-top: 10px;
  justify-content: space-between;
}

@keyframes nftcard_keyframe {
  from {
    top: 100%;
    z-index: -1;
  }
  19%{
    top: 30%;
    transform: translate(-50%, -53%) scale(0.3);
    z-index: -1;
  }
  20%{
    top: 30%;
    transform: translate(-50%, -53%) scale(0.3);
    z-index: 8;
  }
  to {
    top: 45%;
    z-index: 8;
    transform: translate(-50%, -53%) scale(0.8);
  }
}

@media screen and (max-height: 800px) {
    @keyframes nftcard_keyframe {
        from {
          top: 100%;
          z-index: -1;
        }
        19%{
          top: 30%;
          transform: translate(-50%, -53%) scale(0.3);
          z-index: -1;
        }
        20%{
          top: 30%;
          transform: translate(-50%, -53%) scale(0.3);
          z-index: 8;
        }
        to {
          top: 45%;
          z-index: 8;
          transform: translate(-50%, -53%) scale(0.6);
        }
      }
  }