.nftbutton {
  margin: 5px;
  height: 60px;
  width: 60px;
  border: 1px solid black;
  position: relative;
  overflow: hidden;
}
.nftbutton:hover {
  height: 60px;
  width: 60px;
  margin: 3px;
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.nftbutton:active {
  height: 60px;
  width: 60px;
  margin: 3px;
  border: 1px solid rgba(0, 255, 0, 0.5);
}

.nftbutton__discount {
  position: absolute;
  text-align: center;
  bottom: -20px;
  left: 0px;
  color: red;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__title {
  text-align: center;
}
.modal__header,
.modal__body,
.modal__footer {
  background-color: rgb(32, 32, 32);
  border: 1px solid white;
}
