.box1 {
    width: 300px;
    margin: 50px auto;
    border: 4px solid #ca1c21e5;
    padding: 20px;
    text-align: center;
    font-weight: 900;
    color: #ca1c21e5;
    font-family: arial;
    position: relative;
    border-radius: 5px;
    background-color: #0000003f;
  }
  
  
  /* speech bubble 5 */
  
  .sb5:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #00bfb6;
    border-right: 10px solid transparent;
    border-top: 10px solid #00bfb6;
    border-bottom: 10px solid transparent;
    right: -21px;
    top: 6px;
  }
  
  .sb5:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid #fff;
    border-right: 7px solid transparent;
    border-top: 7px solid #fff;
    border-bottom: 7px solid transparent;
    right: -11px;
    top: 10px;
  }
  
  
  /* speech bubble 6 */
  
  .sb6:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #00bfb6;
    border-top: 10px solid #00bfb6;
    border-bottom: 10px solid transparent;
    left: -21px;
    top: 6px;
  }
  
  .sb6:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid #fff;
    border-top: 7px solid #fff;
    border-bottom: 7px solid transparent;
    left: -11px;
    top: 10px;
  }
  
  
  /* speech bubble 7 */
  
  .sb7:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #00bfb6;
    border-right: 10px solid transparent;
    border-top: 10px solid #00bfb6;
    border-bottom: 10px solid transparent;
    left: 20px;
    bottom: -23px;
  }
  
  .sb7:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #fff;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    left: 24px;
    bottom: -13px;
  }
  
  
  /* speech bubble 8 */
  
  .sb8:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #ca1c21e5;
    border-top: 10px solid #ca1c21e5;
    border-bottom: 10px solid transparent;
    right: 20px;
    bottom: -23px;
  }
  
  .sb8:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    right: 24px;
    bottom: -13px;
  }
  