.gallery__div {
  position: absolute;
  margin: 20px;
  padding: 20px;
  top: 0%;
  left: 0px;
  height: 98%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
