.quiz__intro {
  position: absolute;
  margin: 20;
  padding: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.quiz__answer {
  display: flex;
  border: 1px solid blue;
  background-color: rgba(102, 95, 216, 0.8);
  margin: 10px;
  width: auto;
  min-width: 250px;
  max-width: 350px;
  min-height: 60px;
  max-height: 100px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
}
.quiz__answer:hover {
  border: 2px solid green;
}

.quiz__answer__selected {
  display: flex;
  margin: 10px;
  width: auto;
  min-width: 250px;
  max-width: 350px;
  min-height: 60px;
  max-height: 100px;  
  
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
  border: 2px solid green;
  background-color: rgba(78, 162, 78, 0.8);
}

.quiz__submit__div {
  color: black;
  z-index: 50;
  height: 100px;
  width: 100%;
  background-color: rgba(78, 162, 78, 0.9);
  align-items: middle;
  vertical-align: middle;
  text-align: center;
}

.quiz__submit__btn {
  color: black;
  height: 60px;
  width: 220px;
  margin-top: 20px;
}

td,
th {
  text-align: center;
}
