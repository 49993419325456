.mintpanel {
  bottom: 15px;
  padding: 5px;
  text-align: center;
  width: 450px;
}

.mintpanel__prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 25px;
}
.mintpanel__prices > div {
  position: relative;
}

.mintpanel__discount {
  color: lightgreen;
  font-size: 13px;
  position: absolute;
  bottom: -12px;
  left: 10px;
  white-space: nowrap;
}

.spinner__button {
  border-radius: 25px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1px;
  background-color: transparent;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.spinner__button:hover {
  border-radius: 25px;
  border: 1px solid white;
}
